import * as React from 'react';
import { SearchableDropdown } from '@samc/react-ui-core';
import './LocationSimilarRecordSearchPanel.css';
export const CompanyPickList = (props) => {
    const [selected, setSelected] = React.useState([]);
    const { companyList, onChange } = props;
    const handleOnChange = React.useCallback((item) => {
        if (item.selected)
            setSelected([item.id]);
        else
            setSelected([]);
        onChange(item.id);
    }, [onChange]);
    return (React.createElement("div", { style: { display: 'inline-block', width: '300px', marginLeft: '1%', backgroundColor: 'rgb(255, 207, 207)' } },
        React.createElement(SearchableDropdown, { options: companyList.map((item) => ({ id: item.Id, displayText: item.CompanyName })), selectedKeys: selected, onChange: handleOnChange })));
};
export default CompanyPickList;
