import React from 'react';
import { ApiContext } from '@samc/screen-config-api';
import { useQuery } from 'react-query';
import { executeLiftSiftQuery } from '../api/Provider';
export const Company_Picklist_Id = 'CompanyPicklist';
const Company_Picklist = 'Company';
export const useCompanyPicklist = () => {
    const api = React.useContext(ApiContext);
    const requestBody = {
        domainId: Company_Picklist,
        primaryViewId: '-1',
        adhocListViewMembers: [
            {
                scalarDisplayName: 'CompanyName',
                scalarExpression: '[CompanyName]',
            },
            {
                scalarDisplayName: 'Id',
                scalarExpression: '[Id]',
            },
        ],
        sorting: {
            order: 'asc',
            orderBy: {
                scalarExpression: '[CompanyName]',
            },
        },
        paging: { start: 0, stop: 1000000 },
        summaryMode: false,
    };
    const queryResult = useQuery([Company_Picklist_Id], () => executeLiftSiftQuery(api.LiftSiftApi, requestBody, api.requestInit, api.ShowMessage), { retry: 2, refetchOnWindowFocus: false });
    return queryResult;
};
export default useCompanyPicklist;
